import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Head = _resolveComponent("Head");

  var _component_list = _resolveComponent("list");

  var _component_Tabs = _resolveComponent("Tabs");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, {
    title: "调查问卷",
    back: false
  }), _createVNode(_component_Tabs, {
    title: _ctx.titleArr,
    onChangeOther: _ctx.tabsChange
  }, {
    decTit: _withCtx(function (_ref) {
      var item = _ref.item;
      return [_withDirectives(_createElementVNode("span", {
        class: "num"
      }, _toDisplayString(_ctx.totalNum) + "个 ", 513), [[_vShow, item.index == 0 && _ctx.totalNum > 0]])];
    }),
    content0: _withCtx(function () {
      return [_withDirectives(_createVNode(_component_list, {
        ref: "table1",
        totalNum: _ctx.totalNum,
        "onUpdate:totalNum": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.totalNum = $event;
        }),
        "tabs-index": 0,
        "api-name": "getQuestList"
      }, null, 8, ["totalNum"]), [[_vShow, _ctx.tabsIndex == 0]])];
    }),
    content1: _withCtx(function () {
      return [_withDirectives(_createVNode(_component_list, {
        ref: "table2",
        "tabs-index": 1,
        "api-name": "getQuestList"
      }, null, 512), [[_vShow, _ctx.tabsIndex == 1]])];
    }),
    _: 1
  }, 8, ["title", "onChangeOther"])], 64);
}